@import "../../../styles/variables";

.LabelledInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  .Label {
    display: block;
    margin-bottom: 8px;
    color: #000000;
    font-size: 16px;
    line-height: 1;
    margin-left: 4px;
    font-weight: 400;

    @include for-mobile-and-tablet {
      font-size: 14px;
    }
  }

  .InputContainer {
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;

    &:focus-within {
      outline: 1px auto #4fb387;
    }

    .error {
      border: 1px solid rgb(203, 68, 68, 0.65);

      &:focus {
        outline: 1px auto rgb(203, 68, 68, 0.6);
      }
    }
    .disabled {
      background: rgba(239, 239, 239, 0.3);
    }
  }

  .Pre {
    color: #000;
    font-size: 16px;
    padding: 10px 0px 10px 12px;
    font-weight: 400;
    border-radius: 4px;
    line-height: 125%;

    @include for-mobile-only {
      font-size: 14px;
    }
  }

  .Input {
    color: #000;
    font-size: 16px;
    line-height: 125%;
    font-family: "Avenir Next", sans-serif;
    flex: 1;
    border-radius: 4px;
    padding: 10px 12px;
    border: none;
    outline: none;
    width: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    @include for-mobile-only {
      font-size: 14px;
    }

    &::placeholder {
      color: #959595;
      font-size: 16px;
      line-height: 125%;

      @include for-mobile-only {
        font-size: 14px;
      }
    }
  }

  .ErrorText {
    height: 12px;
    display: block;
    margin-bottom: 10px;
    margin-top: 6px;
    color: rgb(203, 68, 68);
    font-size: 12px;
    line-height: 1;
    margin-left: 4px;
    font-weight: 450;
    visibility: hidden;
  }

  .Visible {
    visibility: visible;
  }
}
