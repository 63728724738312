@import "../../../styles/variables";

.PhoneInput {
  display: flex;
  align-items: center;
  padding: 6px;

  border: 1px solid #d3d3d3;
  border-radius: 4px;

  &:focus-within {
    outline: 1px auto #4fb387;
  }

  .Disabled {
    pointer-events: none;
    background: #f2f2f2;
  }

  .Errored {
    border: 1px solid #f13131;
  }

  .Input {
    border: none;
    outline: none;
    font-size: 17px;
    margin-left: 8px;
    flex-grow: 1;
    width: calc(100% - 8px);
    background-color: transparent;
    line-height: 1.75;
    font-weight: 400;

    &:placeholder {
      color: #959595;
    }

    @include for-mobile-only {
      font-size: 14px;
    }
  }

  .InputDisabled {
    color: #959595;
  }
}
