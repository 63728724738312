@import "../../../styles/variables";
.ModalOverlay {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  opacity: 0;
}

.OverlayOpen {
  opacity: 1;
  z-index: 10000000;
}

.ModalContainer {
  position: fixed;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2147483005;
  transition: width 0.3s ease-in-out;

  .open {
    width: 100%;
  }
}

.ModalInnerDiv {
  position: fixed;

  .Center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include for-mobile-only {
      width: calc(100% - 24px);
    }
  }
}

.Sidebar {
  right: -100%;
  bottom: 0;
  top: 0;
  width: 0;
  background-color: white;
  box-shadow: 0px 8px 18px 0px rgb(0 0 0 / 22%);
  transition: all 0.3s ease-in-out;
  width: 550px;

  @include for-mobile-and-tablet {
    width: 100%;
  }
}

.SidebarOpen {
  right: 0;
}
