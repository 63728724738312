@import '../styles/variables';

.quicksell-header+.quicksell-page {
  overflow: auto;
  transition: margin-top 0.2s ease, height 0.2s ease;
}

.quicksell-header:not(.quicksell-header-fixed)+.quicksell-page {
  height: calc(100% - $headerCollapsedHeight);
  margin-top: $headerCollapsedHeight;
}

.quicksell-header.quicksell-header-fixed+.quicksell-page {
  height: calc(100% - $headerCollapsedHeight);
  margin-top: $headerCollapsedHeight;
}

@include for-desktop-only {
  .quicksell-header+.quicksell-page {
    padding-top: $headerHeight - $headerCollapsedHeight;
  }
}

@include for-tablet-only {
  .quicksell-header:not(.quicksell-header-fixed)+.quicksell-page {
    height: calc(100% - $headerCollapsedHeight);
    margin-top: $headerCollapsedHeight;
  }

  .quicksell-header.quicksell-header-fixed+.quicksell-page {
    height: calc(100% - $headerCollapsedHeight);
    margin-top: $headerCollapsedHeight;
  }
}

@include for-mobile-only {
  .quicksell-header:not(.quicksell-header-fixed)+.quicksell-page {
    height: calc(100% - $headerCollapsedHeight);
    margin-top: $headerCollapsedHeight;
  }

  .quicksell-header.quicksell-header-fixed+.quicksell-page {
    height: calc(100% - $headerCollapsedHeight);
    margin-top: $headerCollapsedHeight;
  }
}

.page-section-padding {
  padding: 36px 96px;
}

.page-container-padding {
  padding: 20px;
}

@include for-mobile-and-tablet {
  .page-section-padding {
    padding: 20px;
    max-width: 100%;
  }

  .page-container-padding {
    padding: 20px;
    max-width: 100%;
  }
}

@include for-desktop-only {
  .page-section-padding {
    max-width: $veryLargeDesktopMediaQueryMaxWidth;
    margin: 0px auto;
  }

  .page-container-padding {
    overflow-x: hidden;
    max-width: 1280px;
    margin: 0px;
  }
}

.slick-slider .slick-slide {
  padding: 0 24px;
  box-sizing: border-box;
}

.slick-slider .slick-slide:empty {
  display: none;
}

.slick-slider .slick-slide a {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.slick-slider .slick-slide img {
  max-height: 100%;
  max-width: 100%;
}

.slick-slider.built-with-quicksell-slider .slick-slide a img {
  min-height: 300px;
  object-fit: contain;
}

.slick-slider.testimonial-slider .slick-slide {
  padding: 0px;
}

.slick-slider.playstore-review-slider .slick-slide {
  padding: 18px;
}

.slick-slider.playstore-review-slider .slick-slide>div:not(:first-child) {
  margin-top: 36px;
}

.slick-slider.playstore-review-slider .slick-slide .playstore-review {
  min-width: fit-content;
  height: 165px;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

.slick-slider.video-play-list-slider .slick-list {
  margin-left: -24px;
  margin-right: -24px;
}

.slick-slider.video-play-list-slider .slick-slide {
  padding: 0px 24px;
}

@include for-mobile-only {
  .slick-slider.video-play-list-slider .slick-list {
    margin-left: -18px;
    margin-right: -18px;
  }

  .slick-slider.video-play-list-slider .slick-slide {
    padding: 0px 18px;
  }
}

.iijs-page{
  overflow: auto;
  height: 100%;
  scroll-behavior: smooth;
  background-color: #F3F0EA;
}