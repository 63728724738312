* {
  box-sizing: border-box;
}

:root {
  --background: #FFFFFF;
  --footerBackgroundColor: #F7F5F0;
  --footerBackgroundImage: linear-gradient(to bottom, #FCFBF9, #F7F5F0);
  --storeGridBackgroundColor: #FBF3E0;
  --storeGridBackgroundImage: linear-gradient(to left, #FBF3E0 calc(100% / 3), #FFFFFF);
  --storeGridBackgroundImageMobile: linear-gradient(to bottom, #FBF3E0 calc(100% / 3), #FFFFFF);
  --subHeading: #9B6100;
  --green: #208437;
  --red: #CD3737;
  --lightGreen: #BAD3C6;
  --backgroundGreen: #F6FFF8;
  --borderGreen: #9EC0A6;
  --blue: #3865FF;
  --white: #FFFFFF;
  --black: #000000;
  --gray: #D7D7D7;
  --darkGray: #6F6F6F;
  --cardDescription: #686868;
  --divider: #CFCFCF42;
  --reviewCardWidth: 360px;
  --veryLargeDesktopMediaQueryMinWidth: 1600px;
  --largeDesktopMediaQueryMinWidth: 1200px;
  --desktopMediaQueryMinWidth: 993px;
  --tabletMediaQueryMaxWidth: 992px;
  --tabletMediaQueryMinWidth: 600px;
  --mobileMediaQueryMaxWidth: 599px;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --background: #313131;
//     --white: #000000;
//     --black: #FFFFFF;
//     --cardDescription: #EAEAEA;
//   }
// }

html,
body,
#__next {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Avenir Next';
  line-height: 1.5;
  color: var(--black);
  background-color: var(--background);
  --webkit-tap-highlight-color: #BAD3C6;
}

#__next {
  position: relative;
  overflow: hidden;
}

a,
button {
  color: inherit;
  text-decoration: none;
  font-family: 'Avenir Next';
}

a,
button,
img,
svg,
picture,
video,
iframe {
  --webkit-tap-highlight-color: #BAD3C6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Libre Bodoni';
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0px;
  margin-bottom: 0px;
}

nav > ul,
nav > ol {
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

picture,
img,
svg,
video,
iframe {
  outline: none;
}
