.container {
  position: relative;
  box-sizing: border-box;
  color: black;

  .dropdown {
    position: fixed;
    z-index: 10000;
  }
}
