@import "../../styles/variables";

.MainFormContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .MainFormTitle {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .PhoneInputContainer {
    .PhoneInputTitle {
      display: block;
      margin-bottom: 8px;
      color: #000000;
      font-size: 16px;
      line-height: 1;
      margin-left: 4px;
      font-weight: 400;

      @include for-mobile-and-tablet {
        font-size: 14px;
      }
    }
  }

  .BusinessNatureContainer {
    .BusinessNatureLabel {
      display: block;
      margin-bottom: 8px;
      color: #000000;
      font-size: 16px;
      line-height: 1;
      margin-left: 4px;
      font-weight: 400;

      @include for-mobile-and-tablet {
        font-size: 14px;
      }
    }

    .BusinessNatureTrigger {
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      padding: 10px 12px;
      user-select: none;
      cursor: pointer;
      margin-top: 11px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .TriggerText {
        font-size: 15px;
        font-weight: 400;
        line-height: 135%;

        @include for-mobile-only {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .TriggerArrow {
        transition: all 200ms ease-in;
      }

      .TriggerArrowOpen {
        transform: rotate(180deg);
      }
    }

    .BusinessNatureDropdown {
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      padding: 10px 0;
      user-select: none;
      background: white;

      .Item {
        padding: 8px 18px;
        font-size: 15px;
        font-weight: 400;
        line-height: 135%;
        cursor: pointer;

        &:hover {
          background: #f5f5f5;
        }

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }

      .ItemActive {
        background: #f5f5f590;
        pointer-events: none;
      }
    }
  }

  .Error {
    height: 12px;
    display: block;
    margin-bottom: 6px;
    margin-top: 6px;
    color: rgb(203, 68, 68);
    font-size: 12px;
    line-height: 1;
    margin-left: 4px;
    font-weight: 600;
    visibility: hidden;
  }

  .SubmitButtonContainer {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    width: 100%;

    .SubmitButton {
      width: 100%;
      padding: 8px;
      border-radius: 8px;
    }
  }
}

.FormContainerPadding {
  padding: 36px;

  @include for-mobile-only {
    padding: 24px;
  }
}

.ThankYouContainer {
  padding: 40px 40px 0 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include for-mobile-and-tablet {
    padding: 32px 24px 0 24px;
  }

  .CloseIcon {
    position: absolute;
    top: 12px;
    cursor: pointer;
    right: 12px;
    padding: 4px;
    border-radius: 50%;

    &:hover {
      background: #f2f2f2;
    }
  }

  .OkIcon {
    margin-bottom: 24px;
  }

  .ThankYouText {
    font-size: 18px;
    font-weight: 400;
  }
}
