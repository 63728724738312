@import "./variables";

.text-green {
  color: var(--green);
}

.text-blue {
  color: var(--blue);
}

.text-black {
  color: var(--black);
}

.text-white {
  color: var(--white);
}

.text-bold {
  font-weight: 700;
}

.text-thin {
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-libre-bodoni {
  font-family: "Libre Bodoni";
}

.text-avenir-next {
  font-family: "Avenir Next";
}

.with-header-menu-item-new-feature-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-menu-item-new-feature-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-weight: 500;
  background-color: var(--red);
  color: var(--white);
  font-size: 12px;
  line-height: 12px;
  padding: 3px 8px;
  margin-right: 8px;
  height: 20px;
}

.ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.text-slant-underline {
  text-decoration: none;
  border-bottom: none;
  position: relative;
  padding-bottom: 2.5%;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -12px;
    width: 100%;
    height: 50%;
    background-image: url('/assets/cross_underline.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @include for-mobile-only {
    padding-bottom: 8px;

    &:after {
      bottom: -8px;
      width: 95%;
    }
  }

  @include for-small-mobile-only {
    padding-bottom: 16px;

    &:after {
      bottom: -8px;
      width: 100%;
    }
  }
}
