@import '../../../styles/variables';

.Button {
  cursor: pointer;
  padding: 0px;
  transition: color 0.2s ease, background-color 0.2s ease, border 0.2s ease;
  border-radius: 5px;

  &:disabled,
  &:disabled:hover {
    background-color: var(--gray);
    color: var(--white);
    border: 1px solid var(--gray);
    cursor: not-allowed;
    outline: none;
    box-shadow: none;

    .ButtonLink {
      color: var(--white);
    }
  }

  &.DefaultButton {
    background-color: var(--white);
    color: var(--green);
    border: 1px solid var(--green);

    .ButtonLink {
      color: var(--green);
    }

    &:not(:disabled):hover {
      background-color: var(--green);
      color: var(--white);
      border: 1px solid var(--green);
      outline: none;
      box-shadow: none;

      .ButtonLink {
        color: var(--white);
      }
    }

    &:not(:disabled):active {
      cursor: grabbing;
    }
  }

  &.PrimaryButton {
    background-color: var(--green);
    color: var(--white);
    border: 1px solid var(--green);

    .ButtonLink {
      color: var(--white);
    }

    &:not(:disabled):hover {
      background-color: var(--white);
      color: var(--green);
      border: 1px solid var(--green);
      outline: none;
      box-shadow: none;

      .ButtonLink {
        color: var(--green);
      }
    }

    &:not(:disabled):active {
      cursor: grabbing;
    }
  }

  .ButtonText,
  .ButtonLink {
    display: block;
    height: 100%;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
  }

  .ButtonText {
    padding: 12px 18px;
  }

  @include for-mobile-and-tablet {
    .ButtonText {
      padding: 8px 12px;
    }
  }

  @include for-tablet-only {
    .ButtonText {
      padding: 8px 10px;
    }
  }

  @include for-mobile-only {
    .ButtonText {
      padding: 6px 8px;
    }
  }

  // @media (prefers-color-scheme: dark) {
  //   &:disabled,
  //   &:disabled:hover {
  //     color: var(--black);

  //     .ButtonLink {
  //       color: var(--black);
  //     }
  //   }

  //   &.DefaultButton {
  //     background-color: var(--black);

  //     &:not(:disabled):hover {
  //       color: var(--black);

  //       .ButtonLink {
  //         color: var(--black);
  //       }
  //     }
  //   }

  //   &.PrimaryButton {
  //     color: var(--black);

  //     .ButtonLink {
  //       color: var(--black);
  //     }

  //     &:not(:disabled):hover {
  //       background-color: var(--black);
  //     }
  //   }
  // }
}
