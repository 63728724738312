@import '../../../styles/variables';

.container {
  .flag {
    object-position: center;
    object-fit: contain;
    height: 100%;
  }

  .symbolPlaceholder {
    border: 1px solid #196d48;
    border-radius: 6px;
    background-color: #e8f4ee;
    cursor: pointer;
    font-size: 16px;
    color: #5a5a5a;
    line-height: 135%;
    letter-spacing: -0.4px;
    display: inline-flex;
    align-items: center;
    padding: 8px 6px;
    white-space: pre;

    span {
      font-size: inherit;
      color: #5a5a5a;
      line-height: 1;
      margin-right: 8px;
    }

    svg {
      transition: all 0.2s ease-in-out;
      color: #5a5a5a;
    }

    .reversed {
      transform: rotate(180deg);
    }
  }

  .loading {
    pointer-events: none;
    min-width: 40px;
  }

  .dropdown {
    background: white;
    z-index: 10;
    padding: 8px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid #3f916d;
    box-shadow: 0px 0px 8px 0px #00000020;
    border-radius: 8px;

    .search {
      border: none;
      box-sizing: border-box;
      width: 100%;
      padding: 6px 4px 8px 4px;
      background: transparent;
      border-radius: 8px;
      font-size: 20px;
      border-radius: 4px;

      &:focus {
        outline: none;
      }
    }

    .countries {
      box-sizing: border-box;
      width: 100%;
      max-height: 300px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include for-mobile-and-tablet {
        max-height: 200px;
      }

      .country {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 18px;
        padding: 8px;
        border: 0.5px solid #3f916d;
        background: #3f916d10;
        border-radius: 4px;
        cursor: pointer;
        box-sizing: border-box;
        max-width: 100%;
        margin-bottom: 8px;

        &:hover {
          background: #3f916d20;
        }

        .name {
          display: flex;
          align-items: center;
          flex-grow: 1;
          max-width: 70%;

          .flag {
            width: 28px;
            margin-right: 8px;
            object-fit: contain;
          }

          .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            font-weight: 500;
            font-size: 20px;
          }
        }

        .symbol {
          font-weight: 500;
          font-size: 20px;
          flex-shrink: 0;
        }
      }
    }
  }
}
