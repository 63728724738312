@import '../../../styles/variables';

:export {
  variables: {
    largeDesktopMediaQueryMinWidth: $largeDesktopMediaQueryMinWidth;
    desktopMediaQueryMinWidth: $desktopMediaQueryMinWidth;
    tabletMediaQueryMaxWidth: $tabletMediaQueryMaxWidth;
    tabletMediaQueryMinWidth: $tabletMediaQueryMinWidth;
    mobileMediaQueryMaxWidth: $mobileMediaQueryMaxWidth;
  }
}

.Typography {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  color: inherit;
  border-color: inherit;
}
