$veryLargeDesktopMediaQueryMaxWidth: 1600px;
$veryLargeDesktopMediaQueryMinWidth: 1400px;
$largeDesktopMediaQueryMinWidth: 1200px;
$mediumDesktopMediaQueryMinWidth: 1200px;
$desktopMediaQueryMinWidth: 993px;
$tabletMediaQueryMaxWidth: 992px;
$tabletMediaQueryMinWidth: 600px;
$mobileMediaQueryMaxWidth: 599px;
$smallMobileMediaQueryMaxWidth: 500px;
$verySmallMobileMediaQueryMaxWidth: 360px;

$headerHeight: 132px;
$headerCollapsedHeight: 70px;

@mixin for-very-small-mobile-only {
  @media (max-width: $verySmallMobileMediaQueryMaxWidth) {
    @content;
  }
}

@mixin for-small-mobile-only {
  @media (max-width: $smallMobileMediaQueryMaxWidth) {
    @content;
  }
}

@mixin for-mobile-only {
  @media (max-width: $mobileMediaQueryMaxWidth) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (min-width: $tabletMediaQueryMinWidth) and (max-width: $tabletMediaQueryMaxWidth) {
    @content;
  }
}

@mixin for-large-desktop-only {
  @media (min-width: $largeDesktopMediaQueryMinWidth) {
    @content;
  }
}

@mixin for-very-large-desktop-only {
  @media (min-width: $veryLargeDesktopMediaQueryMinWidth) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: $desktopMediaQueryMinWidth) {
    @content;
  }
}

@mixin for-tablet-and-desktop {
  @media (min-width: $tabletMediaQueryMinWidth) {
    @content;
  }
}

@mixin for-mobile-and-tablet {
  @media (max-width: $tabletMediaQueryMaxWidth) {
    @content;
  }
}
